import React from 'react'
import { ScrollTrigger, Tween } from 'react-gsap'
import tw, { css, styled } from 'twin.macro'
import { toRem, mediaQuery } from '../styles'
import { DogmaLogo } from './icons'

const StyledHeader = styled.header`
  ${tw`flex justify-center items-center w-full`};
  ${tw`fixed z-40`};
  padding: ${toRem(43)} 0 ${toRem(35)};
  background: linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(18, 18, 18, 0) 100%);
  ${mediaQuery(`
    padding: ${toRem(65)} 0 ${toRem(52)};
  `)}
`

const StyledLink = styled.a`
  ${tw`absolute mt-4`}
  left: 50%;
  transform: translate(-50%, -40%);
`

const logoStyles = css`
  ${tw`mx-auto`};
  height: ${toRem(26)};
  ${mediaQuery(`
    height: ${toRem(36)};
  `)}
`

const Header = () => {
  return (
    <ScrollTrigger
      trigger="header"
      start="20px top"
      end="9999"
      onUpdate={({ direction, animation }) => {
        direction === 1 ? animation.play() : animation.reverse()
      }}
    >
      <Tween
        to={{ alpha: 0, y: '-=100' }}
        duration="0.5"
        delay="0.1"
        ease="power2.in"
        playState="paused"
      >
        <StyledHeader>
          <StyledLink href="/">
            <h1 tw="sr-only">Dogma Creative</h1>
            <DogmaLogo css={logoStyles} />
          </StyledLink>
        </StyledHeader>
      </Tween>
    </ScrollTrigger>
  )
}

export default Header
