import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../../config/design-system'

const ActiveMenu = ({ stroke, accentStroke, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 21 24" {...rest}>
      <title>Menu</title>
      <desc>Active menu button state with toggle option to close menu</desc>
      <path stroke={stroke} d="M1 15.833L21 15.833"></path>
      <path
        stroke={accentStroke}
        d="M0 -0.5L19.99 -0.5"
        transform="rotate(-22.552 22.234 1.826) skewX(.136)"
      ></path>
      <path stroke={stroke} d="M1 23.5L21 23.5"></path>
    </svg>
  )
}

ActiveMenu.defaultProps = {
  stroke: colors['dark-text-color'],
  accentStroke: colors['accent-color']
}

ActiveMenu.propTypes = {
  stroke: PropTypes.string,
  accentStroke: PropTypes.string
}

export { ActiveMenu }
