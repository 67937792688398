import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../../config/design-system'

const Menu = ({ stroke, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16" {...rest}>
      <title>Menu</title>
      <desc>Menu button with toggle option to open menu</desc>
      <path stroke={stroke} d="M0 8L20 8"></path>
      <path stroke={stroke} d="M0 0.5L20 0.5"></path>
      <path stroke={stroke} d="M0 15.5L20 15.5"></path>
    </svg>
  )
}

Menu.defaultProps = {
  stroke: colors['light-text-color']
}

Menu.propTypes = {
  stroke: PropTypes.string
}

export { Menu }
