import { useEffect } from 'react'

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    const stringClassName = typeof e.target.className === 'string'

    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      (!stringClassName || (stringClassName && !e.target.className.includes('indicatorContainer')))
    ) {
      callback()
    }
  }

  useEffect(() => {
    document.addEventListener('pointerdown', handleClick)

    return () => {
      document.removeEventListener('pointerdown', handleClick)
    }
  })
}

export { useOutsideClick }
