import React from 'react'
import { Tween, ScrollTrigger } from 'react-gsap'
import tw, { css } from 'twin.macro'
import { colors } from '../../config/design-system'
import { toRem, mediaQuery, tagStyles } from '../styles'
import { DogmaLogo } from './icons'
import samyLogoImg from '../assets/logos/samy.svg'
import jaguarLogoImg from '../assets/logos/jaguar_logo_black.png'

const footerStyles = css`
  ${tw`w-full bg-light-bg-color text-dark-text-color`};
  padding: ${toRem(26)} ${toRem(20)} ${toRem(32)};
  ${mediaQuery(`
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(2, auto);
    padding: ${toRem(50)} ${toRem(32)} ${toRem(32)};
  `)}
  ${mediaQuery(
    `
  padding: ${toRem(50)} ${toRem(32)} ${toRem(32)} ${toRem(60)};
  `,
    'desktop'
  )}
`
const dogmaLogoStyles = css`
  max-width: 13rem;
  width: 120%; ;
`

const logoStyles = css`
  max-width: 9.375rem;
  width: 100%;
`

const sectionStyles = css`
  ${tw`grid grid-cols-2 md:grid-cols-4`};
  grid-template-rows: minmax(${toRem(40)}, auto);
  gap: ${toRem(32)} ${toRem(50)};
  ${mediaQuery(`
    gap: ${toRem(48)} ${toRem(50)};
  `)}
`

const Footer = () => {
  return (
    <ScrollTrigger trigger="main" start="bottom bottom" ease="expo">
      <Tween from={{ alpha: 0, y: 100 }} duration="0.8">
        <footer css={footerStyles}>
          <section css={[sectionStyles, tw`md:col-start-1 md:col-end-3`]}>
            <div tw="col-start-1 col-end-2 md:col-end-3">
              <DogmaLogo fill={colors['dark-text-color']} css={dogmaLogoStyles} />
            </div>

            <div tw="flex flex-col justify-between col-start-1 col-end-2 md:col-end-3">
              <img src={samyLogoImg} css={logoStyles} alt="Samy logo" />
              <p css={tagStyles} tw="mt-3">
                A Samy <wbr />
                Alliance <wbr />
                Company&nbsp;©
              </p>
            </div>
            <div tw="flex flex-col justify-between col-start-2 col-end-3 md:col-start-3 md:col-end-5">
              <img src={jaguarLogoImg} css={logoStyles} alt="Jaguar logo" />
              <p css={tagStyles} tw="mt-3">
                By <wbr />
                Jaguar <wbr />
                Path Ventures
              </p>
            </div>
          </section>
          <section
            css={[
              sectionStyles,
              tw`mt-10 md:col-start-4 md:col-end-9 md:row-start-1 md:row-end-4 md:mt-0`
            ]}
          >
            {/* <strong tw="col-start-1 col-end-3 font-heading font-medium leading-tight md:col-end-5 md:text-header2-lg">
              We collaborate with ambitious brands and people. Let's build:{' '}
              <a href="mailto:hello@dogmamedia.com">hello@dogmamedia.com</a>
            </strong> */}

            <div tw="col-start-1 col-end-2">
              <p css={tagStyles}>Social</p>
              <ul tw="mt-4 mb-16">
                <li tw="font-thin pb-4">
                  <a
                    href="https://www.instagram.com/dogmacreativemedia"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Instagram
                  </a>
                </li>
                <li tw="font-thin">
                  <a
                    href="https://www.linkedin.com/company/dogma-creative"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div tw="col-start-2 col-end-3 md:col-end-5">
              <p css={tagStyles}>Offices</p>
              <ul tw="mt-4 mb-16">
                <li tw="font-thin pb-4">Madrid - SP</li>
                <li tw="font-thin pb-4">New York - NY</li>
                <li tw="font-thin">London - EN</li>
              </ul>
            </div>
          </section>

          <div tw="col-start-1 col-end-12 row-start-4 md:pt-8">
            <a css={tagStyles} href="/privacy-policy" rel="noopener noreferrer">
              Privacy policy
            </a>
            ,{' '}
            <a css={tagStyles} href="/legal-notice" rel="noopener noreferrer">
              Legal notice
            </a>
          </div>
        </footer>
      </Tween>
    </ScrollTrigger>
  )
}

export default Footer
