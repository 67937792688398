import { useCallback, useState, useEffect } from 'react'

const useWindowDimensions = () => {
  const windowExists = typeof window !== 'undefined'

  const getWindowDimensions = useCallback(() => {
    const { innerWidth: viewportWidth, innerHeight: viewportHeight } = windowExists ? window : {}

    return {
      viewportWidth,
      viewportHeight
    }
  }, [windowExists])

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    if (windowExists) {
      function handleResize() {
        setWindowDimensions(getWindowDimensions())
      }

      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [getWindowDimensions, windowExists])

  return windowDimensions
}

export { useWindowDimensions }
