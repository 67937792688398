import React from 'react'
import PropTypes from 'prop-types'
import { colors } from '../../../config/design-system'

const DogmaLogo = ({ fill, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 449.704 107.006" {...rest}>
      <title>Dogma Creative Logo</title>
      <path
        d="M398.643 24.357h-20.964v-.522C377.679 8.439 390.466 0 413.431 0 438.569 0 449.7 7.047 449.7 24.269v38.8h-20.612V46.8c0 7.915-6.785 16.7-25.922 16.7-18.355 0-28.357-4.437-28.357-16.44v-.87c0-18.964 27.052-17.833 54.279-21.834V24.1c0-5.567-3.654-8.351-15.918-8.351-10.786 0-14.527 2.784-14.527 8.176zm8.872 27.662c11.222 0 21.573-1.219 21.573-8.177v-6.7c-24.269 2.522-33.664 3.741-33.664 8.872v.436c0 3.568 2.958 5.569 12.091 5.569z"
        fill={fill}
      ></path>
      <path
        d="M263.479 25.749c0-15.571 6.7-25.749 25.66-25.749 17.4 0 27.054 7.307 28.532 23.139C318.8 9.308 325.847 0 343.42 0c18.962 0 28.7 8.612 28.7 27.488v35.578h-20.7V31.141c0-7.741-3.827-13.135-17.137-13.135-7.219 0-15.135 1.74-16.44 10v35.06h-20.7V31.141c0-7.741-3.827-13.135-16.962-13.135-8.09 0-16.7 1.828-16.7 10.786v34.274h-20.618V.435h20.616z"
        fill={fill}
      ></path>
      <path
        d="M238.868.435V46.8c0 13.917-10.961 21.05-39.491 21.05-25.4 0-36.883-5.654-36.883-16.092V50.54h19.572v.348c0 5.393 5.219 6.784 17.484 6.784 13.135 0 18.615-2.261 18.615-9.394V31.229c0 9.047-6.784 18.093-26.095 18.093-22.792 0-32.359-8.351-32.359-23.834V24.1c0-15.657 9.567-24.1 32.359-24.1 19.311 0 26.1 9.133 26.1 18.093V.435zM199.463 13.4c-12.787 0-19.05 2.871-19.05 11.048v.782c0 7.916 5.915 10.788 19.05 10.788 10.438 0 18.7-1.741 18.7-7.57v-7.48c.002-5.919-8.263-7.568-18.7-7.568z"
        fill={fill}
      ></path>
      <path
        d="M157.283 31.4v.7c0 19.659-12 31.4-39.4 31.4S78.472 51.758 78.472 32.1v-.7c0-19.66 12.005-31.4 39.406-31.4s39.405 11.743 39.405 31.4zm-20.79.522v-.348c0-10.613-5.741-14.267-18.615-14.267s-18.615 3.657-18.615 14.27v.348c0 10.612 5.74 14.266 18.615 14.266s18.615-3.654 18.615-14.266z"
        fill={fill}
      ></path>
      <path
        d="M0 0h35.518c29.327 0 40 11.221 40 30.721v2.011c0 19.346-10.678 30.334-40 30.334H0zm18.339 15.864v31.494h17.333c13.852 0 21.358-3.638 21.358-15.09v-.774c0-11.916-7.506-15.63-21.358-15.63z"
        fill={fill}
      ></path>
      <path
        d="M92.821 97.305v.075c0 5.9-4.23 9.626-12.969 9.626-8.839 0-13.019-4.129-13.019-10.107v-.684c0-5.978 4.18-10.107 13.019-10.107 8.739 0 12.969 3.724 12.969 9.626v.1h-4.559v-.1c0-3.6-2.787-5.446-8.511-5.446-6.155 0-8.359 2.229-8.359 6.105v.329c0 3.876 2.2 6.1 8.359 6.1 5.7 0 8.511-1.874 8.511-5.421v-.1zm33.916 1.468h-6.257v8.106h-4.558V86.235h10.993c6.939 0 9.3 2.2 9.3 6.156v.3c0 2.837-1.343 4.813-4.863 5.623l5.192 8.562h-5.066zm-6.257-8.663V95h6.739c3.089 0 4.407-.481 4.407-2.406v-.1c0-1.926-1.318-2.382-4.407-2.382zm57.16 16.769h-18.415V86.235h18.415v3.926h-13.856v3.927h13.222v3.824h-13.222v5.016h13.856zm40.96-4.813h-11.65l-2.152 4.813h-4.636l9.372-20.644h6.51l9.423 20.644h-4.712zm-1.722-3.825l-4.1-9.169-4.1 9.169zm40.567-7.826v16.464h-4.534V90.415h-8.688v-4.18h21.884v4.18zm31.386-4.18h4.559v20.644h-4.559zm39.607 16.971l7.828-16.971h4.686l-9.7 20.644h-5.7l-9.676-20.644h4.736zm53.501 3.673h-18.414V86.235h18.414v3.926h-13.854v3.927h13.222v3.824h-13.222v5.016h13.854z"
        fill={fill}
      ></path>
    </svg>
  )
}

DogmaLogo.defaultProps = {
  fill: colors['accent-color']
}

DogmaLogo.propTypes = {
  fill: PropTypes.string
}

export { DogmaLogo }
