import { useState, useEffect } from 'react'

const useVideoForViewport = (videoSm, videoLg) => {
  const windowExists = typeof window !== 'undefined'

  const windowWidth = windowExists && window.innerWidth
  const [videoSrc, setVideoSrc] = useState(videoLg)

  useEffect(() => {
    if (windowExists) {
      const videoWidth = windowWidth < 768 ? videoSm : videoLg
      setVideoSrc(videoWidth)
    }
  }, [windowExists, windowWidth, videoSm, videoLg])
  return videoSrc
}

export { useVideoForViewport }
